import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BEMHelper from "react-bem-helper";
import SwiftType from "../components/swift-type";

// Components
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderSolidColor from "../modules/headers/header-solid-color";
import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";

// Helpers, Functions
import { richTextConfig } from "../rich-text-config/rich-text-config";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const bem = new BEMHelper({
  name: "page-content-detail",
});

const ContentDetailPage = ({ data, location, pageContext }) => {
  const {
    seoPageTitle,
    pageTitle,
    slug,
    body,
    previewExcerpt,
    headerDescription,
    shareImage,
  } = data.contentfulTemplateContentDetailPage;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  let withUpdatedSlug = [];
  if (pageContext.relatedPages) {
    pageContext.relatedPages.forEach((page, i) => {
      let obj = { ...page, slug: `../${page.slug}` };
      // service.slug = `../${service.slug}`;
      withUpdatedSlug.push(obj);
    });
  }

  const metaTitle = seoPageTitle || pageTitle;
  return (
    <Layout location={location} additionalClassName={slug} noAnimation = {true}>
      <SwiftType
        customTitle={pageTitle}
        customDesc={headerDescription?.headerDescription}
      />
      {/* <SwiftType
        contentTypes={["blog", "resource"]}
        solutions={[
          "Digital Legal Transformation",
          "Litigitation and Investigation",
        ]}
        tags={["digital", "litigation"]}
      /> */}
      <SEO
        title={metaTitle || "UnitedLex"}
        description={previewExcerpt?.previewExcerpt}
        url={location?.href}
        image={shareImage?.file?.url}
      />
      <HeaderSolidColor
        backgroundColor={"maroon-dark"}
        headerTitle={pageTitle || null}
        headerDescription={
          headerDescription ? headerDescription.headerDescription : null
        }
      />
      <div>
        <div {...bem("main-content-wrapper")}>
          <article {...bem("body")}>
            <div {...bem("body-copy-wrapper")}>
              {/*{documentToReactComponents(JSON.parse(body?.raw), richTextConfig())}*/}
              {renderRichText(body,richTextConfig())}
            </div>
          </article>
          <aside {...bem("aside")}></aside>
        </div>
      </div>
      {pageContext?.relatedPages && (
        <ModuleCarousel
          cards={withUpdatedSlug}
          moduleHeadline={"Learn More About UnitedLex"}
          // submenuTitle={'Learn More'}
        />
      )}
    </Layout>
  );
};

export default ContentDetailPage;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateContentDetailPage(slug: { eq: $slug }) {
      pageTitle
      doNotIndex
      slug
      seoPageTitle
      headerDescription {
        headerDescription
      }
      shareImage {
        file {
          url
        }
      }
      previewExcerpt {
        previewExcerpt
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
              contentful_id
              __typename
              title
              description
              gatsbyImageData
            }
          ... on ContentfulItemKeyStat {
              contentful_id
              __typename
              bigText
              littleText {
                littleText
                id
              }
          }
          ... on ContentfulTemplateContentDetailPage {
            id
            contentful_id
            slug
          }
        }
      }
    }
  }
`;
